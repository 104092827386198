// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-slackbot-js": () => import("./../../../src/pages/slackbot.js" /* webpackChunkName: "component---src-pages-slackbot-js" */),
  "component---src-pages-slackbotsuccess-js": () => import("./../../../src/pages/slackbotsuccess.js" /* webpackChunkName: "component---src-pages-slackbotsuccess-js" */),
  "component---src-templates-single-blog-post-template-js": () => import("./../../../src/templates/singleBlogPostTemplate.js" /* webpackChunkName: "component---src-templates-single-blog-post-template-js" */)
}

